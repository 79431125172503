import React from 'react';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const PopSwal = withReactContent(Swal)

function Loading() {
  return <div>Loading...</div>;
}

export function showLoading(msg){
  PopSwal.fire({ 
    title: msg,
    allowEscapeKey: false,
    allowOutsideClick: false,
    showConfirmButton: false,
    didOpen: () => {
      PopSwal.showLoading();
    }
  });
}

export function loadSuccess(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'success',
    timer: 2000,
    showConfirmButton: false
  });
}

export function loadError(msg){
  PopSwal.fire({ 
    title: msg,
    icon: 'error',
    timer: 2000,
    showConfirmButton: true
  })
}

export function updateloginStatus(status = false,expires=1){
  Cookies.set("islogged", status, {path: "/", expires : expires});
}

export function updateoutletStatus(status = false,expires=1){
  Cookies.set("isoutletset", status, {path: "/", expires : expires});
}

export function setToken(token,expires=1){
  Cookies.set("obulus", token, {path: "/", expires : expires});
}

export function setMerchant(details,expires=1){
  Cookies.set("argos", details, {path: "/", expires : expires});
}

export function setCustomer(customer,expires=1){
  Cookies.set("customer_id", customer, {path: "/", expires : expires});
}

export function setCustomerName(customer,expires=1){
  Cookies.set("customer_name", customer, {path: "/", expires : expires});
}

export function setCustomerHp(hp,expires=1){
  Cookies.set("customer_hp", hp, {path: "/", expires : expires});
}

export function setOutlet(outlet,expires=1){
  Cookies.set("outlet", outlet, {path: "/", expires : expires});
}

export function setMode(mode,expires=1){
  Cookies.set("mode", mode, {path: "/", expires : expires});
}

export function setBanner(banner,expires=1){
  Cookies.set("banner", banner, {path: "/", expires : expires});
}

export function getBanneri(){
  return Cookies.get("banner");
}

export function getMode(){
  return Cookies.get("mode");
}

export function getToken(){
  return Cookies.get("obulus");
}

export function getCustomer(){
  return Cookies.get("customer_id");
}

export function getCustomerName(){
  return Cookies.get("customer_name");
}

export function getCustomerHp(){
  return Cookies.get("customer_hp");
}

export function getMerchantDetail(field = "title"){
  var value = Cookies.getJSON('argos')
  if(typeof value == "undefined"){
    return "";
  }else{
    return value[field];
  }
}

export function getOutletDetail(field = "title"){

  var value = Cookies.getJSON('outlet')
  if(typeof value == "undefined"){
    return "";
  }else{
    return value[field];
  }
  
}

export const dialCodes =[65,1,7,20,27,30,31,32,33,34,36,39,40,41,43,44,45,46,47,48,49,51,52,53,54,55,56,57,58,60,61,62,63,64,66,81,82,84,86,90,91,92,94,95,98,212,213,216,218,220,221,222,223,224,225,226,227,228,229,230,231,232,233,234,235,236,237,238,239,240,241,242,243,244,245,248,249,250,251,252,253,254,255,256,257,258,260,261,262,263,264,265,266,267,268,269,290,291,297,298,299,350,351,352,353,354,355,356,357,358,359,370,371,372,373,374,375,376,377,378,379,380,381,382,385,386,387,389,420,421,423,500,501,502,503,504,505,506,507,508,509,590,591,592,593,594,595,596,597,598,599,670,672,673,674,675,676,677,678,679,680,681,682,683,685,686,687,688,689,690,691,692,850,852,853,855,856,870,880,886,960,961,962,963,964,965,966,967,968,970,971,972,973,974,975,976,977,992,993,994,995,996,998];


export function isLoggedIn(){ 
  return Cookies.get("islogged"); 
}

export function isOutletSet(){ 
  return Cookies.get("isoutletset"); 
}

export function destroySession(){
  Cookies.remove("obulus",{ path: '/' });
  Cookies.remove("islogged",{ path: '/' });
  Cookies.remove("argos",{ path: '/' });
  Cookies.remove("customer_id",{ path: '/' });
  Cookies.remove("customer_hp",{ path: '/' });
  Cookies.remove("customer_name",{ path: '/' });
}

export function permaDeath(){
  Cookies.remove("outlet",{ path: '/' });
  Cookies.remove("isoutletset",{ path: '/' });
}

export function destroyCustomer(){
  Cookies.remove("customer_id",{ path: '/' });
  Cookies.remove("customer_hp",{ path: '/' });
  Cookies.remove("customer_name",{ path: '/' });
}

export const APIHOST = "https://newimage.space/api/v1/";

export const CDN = "http://newimage.space/ogdru/";
export const RewardCDN = "http://newimage.space/ogdru/silas/";
export const TplCDN = "http://newimage.space/ogdru/mibu/";
export const merchantKey = "DW4MCP2B4YCY";
export const merchantId = "15440";